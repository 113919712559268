import React from 'react';
import { useTranslation } from 'react-i18next';
import { HeadPropsI } from '../../meta/Head.component';

export interface TestVideoProps extends HeadPropsI {}

const testVideoPage: React.FC<TestVideoProps> = (props) => {
  const { i18n, t } = useTranslation();
  const lang = i18n.language;
  const [titleSrc, setTitleSrc] = React.useState<any>(undefined);
  const [videoSrc, setVideoSrc] = React.useState<any>(undefined);
  const videoRef = React.useRef<any>();

  const handleChangeSubtitle = (e: any) => {
    setTitleSrc(URL.createObjectURL(e.target.files[0]));
    videoRef.current?.load();
  };

  const handleChangeVideo = (e: any) => {
    setVideoSrc(URL.createObjectURL(e.target.files[0]));
    videoRef.current?.load();
  };

  return (
    <div>
      <h1>Test video</h1>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <div style={{ width: '50%' }}>
          <video style={{ width: '100%' }} ref={videoRef} controls>
            <source src={videoSrc} type="video/mp4" />
            <track label="English" kind="subtitles" srcLang="en" id="englishTitle" src={titleSrc} default />
          </video>
        </div>
        <div style={{ width: '50%', paddingLeft: '20px' }}>
          <p>
            <label>Video</label>
            <input type="file" onChange={(e: any) => handleChangeVideo(e)} />

            <label>Subtitle</label>
            <input type="file" onChange={(e: any) => handleChangeSubtitle(e)} />
          </p>
        </div>
      </div>
    </div>
  );
};

export default testVideoPage;
